export const menuItems = [
  {
    title: "Estadisticas",
    url: "estadisticas",
  },

  {
    title: "Cubico",
    url: "cubico",
  },
  {
    title: "TablasxNumero",
    url: "tablasnumero",
  },
];
